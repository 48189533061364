import type { Callback, Dict, RequestOptions } from "mixpanel-browser"

import mixpanel from "mixpanel-browser"

// Init

const mixpanelState = { hasInitialized: false }

export const mixpanelInit = () => {
  if (!mixpanelState.hasInitialized) {
    mixpanel.init(window.ENV.PUBLIC_MIXPANEL_TOKEN!, {
      track_pageview: "url-with-path-and-query-string",
      persistence: "localStorage", // Switch this to "cookie" if we want to track cross-subdomain
    })

    mixpanelState.hasInitialized = true
  }
}

// Events

const EVENT_NAMES = {
  TAP_SUCCESSFUL: "Tap: Successful",
  TAP_FAILED: "Tap: Failed",
  BEAM_PLAY: "Beam: Play",
  AUDIO_DOWNLOAD: "Audio: Download",
  AUDIO_STREAM: "Audio: Stream",
  LINK_CLICK: "Link: Click",
  PRODUCT_VIEWED: "Product: Viewed",
} as const

type EventName = keyof typeof EVENT_NAMES

type EventProperties = {
  TAP_SUCCESSFUL: { "Tap ID": string }
  TAP_FAILED: { error: string }
  BEAM_PLAY: { "Beam ID": string; beamType: "takeover" | "featured" | "normal" }
  AUDIO_DOWNLOAD: { "Audio ID": string }
  AUDIO_STREAM: { "Audio ID": string; "Track ID": string }
  LINK_CLICK: { "Link ID": string }
  PRODUCT_VIEWED: {
    "Product Handle": string
    "Referral Source"?: string
    Referrer?: string
    "Is User Referrer"?: boolean
  }
}

// Track

export function track<T extends EventName>(
  event: T,
  properties: EventProperties[T] & Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
) {
  const mixpanelEventName = EVENT_NAMES[event]
  return mixpanel.track(mixpanelEventName, properties, optionsOrCallback, callback)
}

export const register = (...args: Parameters<typeof mixpanel.register>) =>
  mixpanel.register(...args)

export function track_links<T extends EventName>(
  query: Parameters<typeof mixpanel.track_links>[0],
  event_name: T,
  properties: (el: HTMLAnchorElement) => EventProperties[T] & Dict,
) {
  const mixpanelEventName = EVENT_NAMES[event_name]
  return mixpanel.track_links(query, mixpanelEventName, properties)
}
